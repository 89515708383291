import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { db, auth } from './FirebaseConfig';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from '../styles/ResumeDisplay.module.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { LoadingContext } from './LoadingContext';
import { useNavigate } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import { analytics } from './FirebaseConfig';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;

// const themeMap = {
//   1: 'dev-ats',
//   2: 'onepage-plus',
//   3: 'kendall',
//   4: 'stackoverflow-crewshin',
//   5: 'autumn-custom',
//   6: 'engineering',
// };

logEvent(analytics, 'Resume_Page');

const themeMap = {
  1: 'ATSume - ATS friendly resume',
  2: 'Boxable - Designer level',
  3: 'Orangy - Orange with a twist',
  4: 'ImageFul - For your image'
};

const ResumeDisplay = () => {
  const [coverLetter, setCoverLetter] = useState('');
  const [pdfUrl, setPdfUrl] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState(1); 
  const [error, setError] = useState(null);
  const [copied, setCopied] = useState(false);
  const { loading, setLoading } = useContext(LoadingContext);
  const navigate = useNavigate();

  useEffect(() => {
    const storedCoverLetter = localStorage.getItem('coverLetter');
    if (storedCoverLetter) {
      setCoverLetter(storedCoverLetter);
    }
    else
    {
      const toastId = "jobWarning";
      if(!toast.isActive(toastId))
      {
        toast.warning("You have to enter job description first.", { toastId });
        navigate("/job");
      }
    }
    generateAndDisplayPDF(selectedTheme);
  }, [navigate,selectedTheme]);

  const generateAndDisplayPDF = async (theme) => {
    try {
      setLoading(true, 'generating');
      setError(null);

      const storedData = localStorage.getItem('newResumeData');
      if (!storedData) {
        toast.error("Resume details not found.");
        throw new Error('No resume data found in local storage');
      }
      const parsedData = JSON.parse(storedData);

      const user = auth.currentUser;
      if (!user) {
        toast.error("Unauthenticated");
        throw new Error('User is not authenticated');
      }

      const token = await user.getIdToken(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/generate-resume?theme=${theme}`,
        parsedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'json',
        }
      );
      setPdfUrl(response.data.url);
      setDownloadUrl(response.data.download);
    } catch (error) {
      setError('Error generating resume. Please try again later.');
      toast.error("Could not generate resume.");
    } finally {
      setLoading(false);
    }
  };

  const handleThemeChange = (event) => {
    setSelectedTheme(event.target.value);
  };

  const downloadCoverLetterAsText = () => {
    const custElement = document.createElement('a');
    const file = new Blob([coverLetter], { type: 'text/plain' });
    custElement.href = URL.createObjectURL(file);
    custElement.download = 'CoverLetter.txt';
    document.body.appendChild(custElement);
    custElement.click();
  };

  const downloadPdf = () => {
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'Resume.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setLoading(false);
    setNumPages(numPages);
  };

  const onDocumentLoadError = ({ numPages }) => {
    setLoading(false);
    setError("Couldn't load pdf");
  };

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12'>
          <h2>Generated Resume</h2>
          <div id='resume' className={`${styles.pdfContainer} pdfContainer`}>
            {loading ? (
              <p>Loading PDF...</p>
            ) : error ? (
              <p>{error}</p>
            ) : pdfUrl ? (
              <div>
                <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onDocumentLoadError}>
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} className={`${styles.page} page`} scale={1.9} />
                  ))}
                </Document>
              </div>
            ) : (
              <p>No PDF to display</p>
            )}
          </div>
          {downloadUrl &&
          <button className='btn btn-primary btn-lg mt-3' onClick={downloadPdf}>
            Download Resume
          </button>
          }
          <div className='form-group mt-3'>
            <label htmlFor='themeSelect'>Select Theme:</label>
            <select
              id='themeSelect'
              className='form-control'
              value={selectedTheme}
              onChange={handleThemeChange}
            >
              {Object.entries(themeMap).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <br />
      <div className='row'>
        <div className='col-12'>
          <h2>Cover Letter</h2>
          <div className='p-3 mb-2 bg-light text-dark' style={{ whiteSpace: 'pre-wrap', textAlign: 'left' }}>
            {coverLetter.replace(/\\n/g, '\n').replace(/['"]+/g, '')}
          </div>
          {/* <button className='btn btn-primary' onClick={downloadCoverLetterAsText}>
            Download Cover Letter as Text
          </button>
          &nbsp;&nbsp; */}
          <CopyToClipboard text={coverLetter.replace(/\\n/g, '\n').replace(/['"]+/g, '')}
          onCopy={() => toast.success("Successfully copied")}>
          <button className='btn btn-primary btn-lg'>Copy</button>
        </CopyToClipboard>
        </div>
      </div>
    </div>
  );
};

export default ResumeDisplay;
