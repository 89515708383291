import React, { createContext, useState } from 'react';

export const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [loadingState, setLoadingState] = useState({
    isLoading: false,
    page: '',
  });

  const setLoading = (isLoading, page = '') => {
    setLoadingState({ isLoading, page });
  };

  return (
    <LoadingContext.Provider 
      value={{ ...loadingState, setLoading }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

