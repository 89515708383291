import React, { useContext, useState, useEffect } from 'react';
import RingLoader from "react-spinners/RingLoader";
import { LoadingContext } from './LoadingContext';

const pageMessagesMap = {
  parsing: [
    "Hang on while we parse your resume for you...",
    "Reading your resume...",
    "Analyzing relevant keywords...",
    "Identifying relevant sections...",
    "Analyzing you resume data...",
    "Your resume looks good, let's make it the best...",
    "Mapping your resume...",
    "Usually it doesn't take this long, but hold on...",
    "There we are..."
  ],
  revising: [
    "Abracadabra, that's DevSume doing it's magic...",
    "Reading you resume...",
    "Booting up advanced resume analysis...",
    "Applying insights to check ATS readiness...",
    "Identifying key experiences for optimization...",
    "Distilling role-specific accomplishments...",
    "Cross-referencing keywords against ATS criteria...",
    "Refining text to be fully ATS-compliant...",
    "Re-checking for any missing achievements...",
    "Performing final compliance review...",
    "Polishing up the final ATS-ready version...",
    "Crafting your new awesome resume...",
    "Usually it doesn't take this long, but hold on...",
    "There we are..."
  ],
  generating: [
    "Generating the resume for you...",
    "Booting up the resume creation algorithm",
    "Hold on while the resume is parsed into PDF...",
    "You new awesome resume is almost here...",
    "Initializing final touches...",
    "Applying ATS friendly theme...",
    "Usually it doesn't take this long, but hold on...",
    "There we are..."
  ],
  default: [
    "Please wait while DevSume does it's magic...",
    "Loading data...",
    "Initializing advanced algorithms...",
    "Gathering specialized data sets...",
    "Wrapping up the final touches...",
    "Almost done...",
    "Usually it doesn't take this long, but hold on...",
    "There we are..."
  ]
};

const Loader = () => {
  const { isLoading, page } = useContext(LoadingContext);
  const [messageIndex, setMessageIndex] = useState(0);

  const messages = pageMessagesMap[page] || pageMessagesMap.default;

  useEffect(() => {
    let intervalId;

    if (isLoading) {
      setMessageIndex(0);

      intervalId = setInterval(() => {
        setMessageIndex(prevIndex => {
          if (prevIndex < messages.length - 1) {
            return prevIndex + 1;
          }
          return prevIndex;
        });
      }, 8000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isLoading, messages]);

  if (!isLoading) return null;

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  return (
    <div style={styles.backdrop}>
      <div style={styles.content}>
        <RingLoader
          color="white"
          loading={isLoading}
          cssOverride={override}
          size={150}
          aria-label="Hold on while DevSume is doing the magic..."
          data-testid="loader"
        />
        {/* Current message */}
        <p style={styles.message}>{messages[messageIndex]}</p>
      </div>
    </div>
  );
};

const styles = {
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  content: {
    textAlign: 'center',
  },
  message: {
    color: 'white',
    marginTop: '20px',
    fontSize: '1.2rem',
  }
};

export default Loader;

