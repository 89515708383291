import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, provider, signInWithPopup, onAuthStateChanged, signInWithEmailAndPassword, sendPasswordResetEmail, createUserWithEmailAndPassword } from './FirebaseConfig';
import GoogleButton from 'react-google-button';
import Typewriter from './Typewriter';
import { logEvent } from 'firebase/analytics';
import { analytics } from './FirebaseConfig';
import axios from 'axios';

const SocialLogin = () => {

  logEvent(analytics, 'Login_Page');

  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [error, setError] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [revisionCounts, setRevisionCounts] = useState(0);

  const texts = [
    "A techie's true friend!",
    "Revise your resume to match job requirements.",
    "Get ATS friendly revised resumes :)",
    "Option to parse your current resume included.",
    "Enter the job description applying for...",
    "That's it! We'll generate your new resume.",
    "You can choose any change to persist.",
    "Go ahead, Get started..."
  ];

  useEffect(() => {
    const fetchRevisionCounts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/revisionCount`);
        setRevisionCounts(response.data.totalCount);
      } catch (error) {
        console.error('Error fetching revision counts:', error);
      }
    };

    fetchRevisionCounts();
  }, []);

  const handleLogin = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        navigate('/me');
      })
      .catch((error) => {
        console.error("Error during login", error);
      });
  };

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    try {
      const email = document.getElementById("email").value;
      const password = document.getElementById("password").value;
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/me');
    } catch (error) {
      if (error.message.includes('invalid-credential')) {
        setError("Invalid Username/Password");
      }
      else if (error.message.includes('invalid-email')) {
        setError("Invalid Username/Password");
      } else {
        setError(error.message);
      }
    }
  };

  const handleEmailSignup = async (e) => {
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;
    e.preventDefault();
    if (password !== passwordConfirm) {
      setError('Passwords do not match');
      return;
    }
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      navigate('/me');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleResetPassword = async () => {
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;
    if (!email) {
      setError('Please enter your email to reset password.');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setError('Password reset email sent. Please check your inbox.');
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        navigate('/me');
      } else {
        setUser(null);
      }
    });
  }, [navigate]);

  return (
    <div style={{ minHeight: '60vh', color: 'white' }}>
      <div className='seo' style={{fontSize:'0px'}}>
      DevSume is a groundbreaking AI-based resume revision tool, proudly standing as the first and only such tool available to the public that offers free resume generation. DevSume makes the process incredibly simple: just upload your resume, and DevSume will parse it for you. Enter the job description you're applying for, and DevSume will generate a revised resume along with a personalized cover letter. The resumes generated by DevSume are ATS-friendly, ensuring you crack through the screening process. DevSume adds the skills mentioned in the job description during the revision of your resume and enhances your experiences to match job requirements. The cover letter generated by DevSume includes company details to make you sound very interested in the position. DevSume is absolutely free to use, though we have placed a limitation of 5 resume revisions per user per day. For more details, check our contact page on DevSume.
      </div>
      {/* <div className='autoscrollable-wrapper' style={{ fontSize: '300%', textAlign: 'center', padding: '20px', height: '10vh' }}>
        <Typewriter texts={texts} />
      </div> */}
      <section className="login-block">
        <div className="container-fluid">
          <div className="row">
            <div className='col-sm-3'></div>
            <div className="col-sm-6">
              <form className="md-float-material form-material" onSubmit={isLogin ? handleEmailLogin : handleEmailSignup}>
                <div className="auth-box card bg-dark" style={{ color: 'white', border: 'none' }}>
                  <div className="card-block">
                    <div className="row">
                      <div className="col-md-12">
                        <h3 className="text-center heading">{isLogin ? 'Login to your account' : 'Signup to DevSume'}</h3>
                      </div>
                    </div>
                    <div className="form-group form-primary">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        name="email"
                        //value={email}
                        //onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        id="email"
                        style={{ width: '98%' }}
                      />
                    </div>
                    <div className="form-group form-primary">
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        name="password"
                        placeholder="Password"
                        //value={password}
                        //onChange={(e) => setPassword(e.target.value)}
                        id="password"
                        style={{ width: '98%' }}
                      />
                    </div>
                    {!isLogin && (
                      <div className="form-group form-primary">
                        <input
                          type="password"
                          className="form-control form-control-lg"
                          name="password_confirm"
                          placeholder="Repeat password"
                          value={passwordConfirm}
                          onChange={(e) => setPasswordConfirm(e.target.value)}
                          id="password_confirm"
                        />
                      </div>
                    )}
                    <div className="row">
                      <div className="col-md-12">
                        <input
                          type="submit"
                          className="btn btn-primary btn-lg btn-block waves-effect text-center m-b-20"
                          name="submit"
                          value={isLogin ? 'Login' : 'Signup Now'}
                        />
                        {(error.includes('Invalid Username/Password') || error.includes('Please enter your email to reset password.')) && (
                          <button className='btn btn-warning btn-lg resetPasswordButton' style={{margin:'auto', marginLeft:15}} onClick={handleResetPassword}>Reset Password</button>
                        )}
                      </div>
                    </div>
                    <div className="or-container">
                      <div className="line-separator"></div>
                      <div className="or-label"><strong>OR</strong></div>
                      <div className="line-separator"></div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <GoogleButton
                          type='light'
                          onClick={handleLogin}
                          style={{ margin: 'auto' }}
                        />
                      </div>
                    </div>
                    <br />
                    {isLogin ? (
                      <div className="text-inverse text-center">
                        <p>Don't have an account?</p>
                        <button 
                          type="button"
                          className="btn btn-primary btn-lg mt-3" 
                          onClick={() => setIsLogin(false)}
                        >
                          Signup
                        </button>
                      </div>
                    ) : (
                      <div className="text-inverse text-center">
                        <p>Already have an account?</p>
                        <button 
                          type="button"
                          className="btn btn-primary btn-lg mt-3" 
                          onClick={() => setIsLogin(true)}
                        >
                          Login
                        </button>
                      </div>
                    )}
                    {error && (
                      <div>
                        <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
            <div className='col-sm-3'></div>
          </div>
        </div>
      </section>
      <br></br>
      <br></br>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-2 col-sm-1"></div>
          <div className="col-lg-6 col-md-8 col-sm-10">
          <div>
              <h1 style={{color:"white"}}>{revisionCounts} RESUME REVISIONS</h1>
            </div>
            <br />
            <br></br>
            <div>
              <h4>What's DevSume?</h4>
              <p>
                DevSume is an AI based resume revision tool. We're proud to be the first and only such tool available to the public that offers free resume generation.
              </p>
            </div>
            <br />
            <div>
              <h4>How does DevSume work?</h4>
              <p>
                DevSume is very simple. Simply upload your resume and we'll parse it for you. Enter the job description you're applying for and we'll generate a revised resume for you along with a cover letter.
              </p>
            </div>
            <br />
            <div>
              <h4>How good are the resume and cover letter?</h4>
              <p>
                The resumes generated by DevSume are ATS-friendly to ensure that you crack through the screening process. The skills mentioned in the job description are added during revision to your resume, and experiences are enhanced to match job requirements. The cover letter includes company details to make you sound very interested in the position.
              </p>
            </div>
            <br />
            <div>
              <h4>Is DevSume free?</h4>
              <p>
                Absolutely free to use. However, we have placed a limitation of 5 resume revisions per user per day. Do check our contact page for details ;)
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-2 col-sm-1"></div>
        </div>
      </div>

    </div>
  );
};

export default SocialLogin;
